import HomePage from '@/pages/home/HomePage.vue'
import CloudUserGuidePage from '@/pages/cloud/CloudUserGuidePage.vue'
import MobileUserGuidePage from '@/pages/mobile/MobileUserGuidePage.vue'
import ResourcePage from '@/pages/resources/ResourcePage.vue'
import GettingStartedPage from '@/pages/api/GettingStartedPage.md'
import AuthorizationPage from '@/pages/api/authorization/AuthorizationPage.vue'
import BillingPage from '@/pages/api/billing/BillingPage.vue'
import BillingWithRatesPage from '@/pages/api/billing-with-rates/BillingWithRatesPage.vue'
import CategoriesPage from '@/pages/api/categories/CategoriesPage.vue'
import CertificationsPage from '@/pages/api/certifications/CertificationsPage.vue'
import ClassesPage from '@/pages/api/classes/ClassesPage.vue'
import CostCentersPage from '@/pages/api/cost-centers/CostCentersPage.vue'
import CostCodesPage from '@/pages/api/cost-codes/CostCodesPage.vue'
import DepartmentsPage from '@/pages/api/departments/DepartmentsPage.vue'
import DescriptionsPage from '@/pages/api/descriptions/DescriptionsPage.vue'
import EntitiesPage from '@/pages/api/entities/EntitiesPage.vue'
import InventoryPage from '@/pages/api/inventory/InventoryPage.vue'
import InventoryProcurementPage from '@/pages/api/inventory-procurement/InventoryProcurementPage.vue'
import VisibilityPage from '@/pages/api/visibility/VisibilityPage.vue'
import LocationsPage from '@/pages/api/locations/LocationsPage.vue'
import LostAndFoundPage from '@/pages/api/lost-and-found/lostAndFound.vue'
import ManufacturersPage from '@/pages/api/manufactures/ManufacturersPage.vue'
import ModelsPage from '@/pages/api/models/ModelsPage.vue'
import PurchaseOrdersPage from '@/pages/api/purchase-orders/PurchaseOrdersPage.vue'
import PurchaseOrdersLinesPage from '@/pages/api/purchase-orders-lines/PurchaseOrdersLinesPage.vue'
import ServiceRequestPage from '@/pages/api/service-request/ServiceRequestPage.vue'
import ShippingMethodsPage from '@/pages/api/shipping-methods/ShippingMethodsPage.vue'
import StatusCodesPage from '@/pages/api/status-codes/StatusCodesPage.vue'
import VendorsPage from '@/pages/api/vendors/VendorsPage.vue'
import WorkOrdersPage from '@/pages/api/work-orders/WorkOrdersPage.vue'
import WorkOrderChargesPage from '@/pages/api/work-orders-charges/WorkOrderChargesPage.vue'
import OverviewPage from '@/pages/integrations/OverviewPage.md'
import VistaSetupPage from '@/pages/integrations/VistaSetupPage.md'
import VistaDataMappingPage from '@/pages/integrations/VistaDataMappingPage.md'
import ImportingJobCostsPage from '@/pages/integrations/ImportingJobCostsPage.md'
import VistaPurchaseOrderPage from '@/pages/integrations/VistaPurchaseOrderPage.md'
import VistaReceiptsPage from '@/pages/integrations/VistaReceiptsPage.md'
import SpectrumSetupPage from '@/pages/integrations/SpectrumSetupPage.md'
import SpectrumDataMappingPage from '@/pages/integrations/SpectrumDataMappingPage.md'
import CMICSetupPage from '@/pages/integrations/CMICSetupPage.md'
import CMICDataMappingPage from '@/pages/integrations/CMICDataMappingPage.md'
import CMICImportingJobCostsPage from '@/pages/integrations/CMICImportingJobCostsPage.md'
import Sage300CRESetupPage from '@/pages/integrations/Sage300CRESetupPage.md'
import Sage300CREDataMappingPage from '@/pages/integrations/Sage300CREDataMappingPage.md'
import Sage300CREImportingJobCostsPage from '@/pages/integrations/Sage300CREImportingJobCostsPage.md'
import Sage100cSetupPage from '@/pages/integrations/Sage100cSetupPage.md'
import Sage100cDataMappingPage from '@/pages/integrations/Sage100cDataMappingPage.md'
import SingleSignOnSamlSetupPage from '@/pages/integrations/SingleSignOnSamlSetupPage.md'
import SingleSignOnAzureADQuickStartPage from '@/pages/integrations/SingleSignOnAzureADQuickStartPage.md'
import ECMSSetupPage from '@/pages/integrations/ECMSSetupPage.md'
import ECMSDataMappingPage from '@/pages/integrations/ECMSDataMappingPage.md'
import ProcoreSetupPage from '@/pages/integrations/ProcoreSetupPage.md'
import ProcoreDataMappingPage from '@/pages/integrations/ProcoreDataMappingPage.md'
import DEWALTSetupPage from '@/pages/integrations/DEWALTSetupPage.md'
import DEWALTDataMappingPage from '@/pages/integrations/DEWALTDataMappingPage.md'
import DEWALTTrackingDataPage from '@/pages/integrations/DEWALTTrackingDataPage.md'
import GeoTabsSetupPage from '@/pages/integrations/GeoTabsSetupPage.md'
import GeoTabsDataMappingPage from '@/pages/integrations/GeoTabsDataMappingPage.md'
import GpsInsightSetupPage from '@/pages/integrations/GpsInsightSetupPage.md'
import GpsInsightDataMappingPage from '@/pages/integrations/GpsInsightDataMappingPage.md'
import IntacctSetupPage from '@/pages/integrations/IntacctSetupPage.md'
import IntacctDataMappingPage from '@/pages/integrations/IntacctDataMappingPage.md'
import ComplianceIntroductionPage from '@/pages/compliance/ComplianceIntroductionPage.md'
import ComplianceSystemArchitecturePage from '@/pages/compliance/ComplianceSystemArchitecturePage.md'
import ComplianceBackupRestorePage from '@/pages/compliance/ComplianceBackupRestorePage.md'
import ComplianceNetworkRequirementsPage from '@/pages/compliance/ComplianceNetworkRequirementsPage.md'
import ComplianceFAQPage from '@/pages/compliance/ComplianceFAQPage.md'
import SupportPage from '@/pages/support/SupportPage.vue'
import SupportThanksPage from '@/pages/support/SupportThanksPage.vue'
import Login from '@/pages/authorization/Login.vue'
import store from '../store/index'
import router from '.'
import ClientLibraryPage from '@/pages/api/client-library/ClientLibraryPage.vue'
import ODataApiPage from '@/pages/odata/ODataApiPage.md'
import PowerBiQuickStartPage from '@/pages/odata/PowerBiQuickStartPage.md'
import SafetyTrainersPage from '@/pages/api/safety-trainers/SafetyTrainersPage.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next()
      } else {
        router.push('/')
      }
    },
    component: Login
  },
  {
    path: '',
    name: 'Home',
    component: {
      render (c) {
        return c('router-view')
      }
    },
    meta: {
      breadcrumb: 'Home',
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: HomePage
      },
      {
        path: 'Resources',
        name: 'Resources',
        routeName: 'Technical Documentation',
        linkRoute: 'Resources',
        group: 'Home',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        meta: {
          breadcrumb: 'Resources'
        },
        children: [
          {
            path: '',
            name: 'Resources',
            component: ResourcePage
          },
          {
            path: 'APIs',
            name: 'Getting Started',
            routeName: 'API',
            linkRoute: 'Getting Started',
            group: 'Resources',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              breadcrumb: 'API'
            },
            children: [
              {
                path: '',
                group: 'Summary',
                name: 'Getting Started',
                linkRoute: 'Getting Started',
                component: GettingStartedPage,
                meta: {
                  breadcrumb: 'Getting Started'
                }
              },
              {
                path: 'ClientLibrary',
                name: 'ClientLibrary',
                component: ClientLibraryPage,
                meta: {
                  hideNavbar: true
                }
              },
              {
                path: 'Authorization',
                name: 'Authorization',
                linkRoute: 'Authorization',
                group: 'Resources',
                component: AuthorizationPage,
                meta: {
                  breadcrumb: 'Authorization'
                }
              },
              {
                path: 'Billing',
                name: 'Job Cost & Billing',
                linkRoute: 'Job Cost & Billing',
                group: 'Resources',
                component: BillingPage,
                meta: {
                  breadcrumb: 'Job Cost & Billing'
                }
              },
              {
                path: 'BillingWithRates',
                name: 'Job Cost & Billing with Rates',
                linkRoute: 'Job Cost & Billing with Rates',
                group: 'Resources',
                component: BillingWithRatesPage,
                meta: {
                  breadcrumb: 'Job Cost & Billing with Rates'
                }
              },
              {
                path: 'Categories',
                name: 'Categories',
                linkRoute: 'Categories',
                group: 'Resources',
                component: CategoriesPage,
                meta: {
                  breadcrumb: 'Categories'
                }
              },
              {
                path: 'Certifications',
                name: 'Certifications',
                linkRoute: 'Certifications',
                group: 'Resources',
                component: CertificationsPage,
                meta: {
                  breadcrumb: 'Certifications'
                }
              },
              {
                path: 'Classes',
                name: 'Classes',
                linkRoute: 'Classes',
                group: 'Resources',
                component: ClassesPage,
                meta: {
                  breadcrumb: 'Classes'
                }
              },
              {
                path: 'CostCenters',
                name: 'Cost Centers',
                linkRoute: 'Cost Centers',
                group: 'Resources',
                component: CostCentersPage,
                meta: {
                  breadcrumb: 'Cost Centers'
                }
              },
              {
                path: 'CostCodes',
                name: 'Cost Codes',
                linkRoute: 'Cost Codes',
                group: 'Resources',
                component: CostCodesPage,
                meta: {
                  breadcrumb: 'Cost Codes'
                }
              },
              {
                path: 'Departments',
                name: 'Departments',
                linkRoute: 'Departments',
                group: 'Resources',
                component: DepartmentsPage,
                meta: {
                  breadcrumb: 'Departments'
                }
              },
              {
                path: 'Descriptions',
                name: 'Descriptions',
                linkRoute: 'Descriptions',
                group: 'Resources',
                component: DescriptionsPage,
                meta: {
                  breadcrumb: 'Descriptions'
                }
              },
              {
                path: 'Entities',
                name: 'Entities',
                linkRoute: 'Entities',
                group: 'Resources',
                component: EntitiesPage,
                meta: {
                  breadcrumb: 'Entities'
                }
              },
              {
                path: 'Inventory',
                name: 'Inventory',
                linkRoute: 'Inventory',
                group: 'Resources',
                component: InventoryPage,
                meta: {
                  breadcrumb: 'Inventory'
                }
              },
              {
                path: 'InventoryProcurement',
                name: 'Inventory Procurement',
                linkRoute: 'Inventory Procurement',
                group: 'Resources',
                component: InventoryProcurementPage,
                meta: {
                  breadcrumb: 'Inventory Procurement'
                }
              },
              {
                path: 'Visibility',
                name: 'List Visibility',
                linkRoute: 'List Visibility',
                group: 'Resources',
                component: VisibilityPage,
                meta: {
                  breadcrumb: 'List Visibility'
                }
              },
              {
                path: 'Locations',
                name: 'Locations',
                linkRoute: 'Locations',
                group: 'Resources',
                component: LocationsPage,
                meta: {
                  breadcrumb: 'Locations'
                }
              },
              {
                path: 'LostAndFound',
                name: 'Lost And Found',
                linkRoute: 'Lost And Found',
                group: 'Resources',
                component: LostAndFoundPage,
                meta: {
                  breadcrumb: 'Lost And Found'
                }
              },
              {
                path: 'Manufacturers',
                name: 'Manufacturers',
                linkRoute: 'Manufacturers',
                group: 'Resources',
                component: ManufacturersPage,
                meta: {
                  breadcrumb: 'Manufacturers'
                }
              },
              {
                path: 'Models',
                name: 'Models',
                linkRoute: 'Models',
                group: 'Resources',
                component: ModelsPage,
                meta: {
                  breadcrumb: 'Models'
                }
              },
              {
                path: 'PurchaseOrders',
                name: 'Purchase Orders',
                linkRoute: 'Purchase Orders',
                group: 'Resources',
                component: PurchaseOrdersPage,
                meta: {
                  breadcrumb: 'Purchase Orders'
                }
              },
              {
                path: 'PurchaseOrdersLines',
                name: 'Purchase Orders Lines',
                linkRoute: 'Purchase Orders Lines',
                group: 'Resources',
                component: PurchaseOrdersLinesPage,
                meta: {
                  breadcrumb: 'Purchase Orders Lines'
                }
              },
              {
                path: 'SafetyTrainers',
                name: 'Safety Trainers',
                linkRoute: 'Safety Trainers',
                group: 'Resources',
                component: SafetyTrainersPage,
                meta: {
                  breadcrumb: 'SafetyTrainers'
                }
              },
              {
                path: 'ServiceRequest',
                name: 'Service Request',
                linkRoute: 'Service Request',
                group: 'Resources',
                component: ServiceRequestPage,
                meta: {
                  breadcrumb: 'Service Request'
                }
              },
              {
                path: 'ShippingMethods',
                name: 'Shipping Methods',
                linkRoute: 'Shipping Methods',
                group: 'Resources',
                component: ShippingMethodsPage,
                meta: {
                  breadcrumb: 'Shipping Methods'
                }
              },
              {
                path: 'StatusCodes',
                name: 'Status Codes',
                linkRoute: 'Status Codes',
                group: 'Resources',
                component: StatusCodesPage,
                meta: {
                  breadcrumb: 'Status Codes'
                }
              },
              {
                path: 'Vendors',
                name: 'Vendors',
                linkRoute: 'Vendors',
                group: 'Resources',
                component: VendorsPage,
                meta: {
                  breadcrumb: 'Vendors'
                }
              },
              {
                path: 'WorkOrders',
                name: 'Work Orders',
                linkRoute: 'Work Orders',
                group: 'Resources',
                component: WorkOrdersPage,
                meta: {
                  breadcrumb: 'Work Orders'
                }
              },
              {
                path: 'WorkOrderCharges',
                name: 'Work Order Charges',
                linkRoute: 'Work Order Charges',
                group: 'Resources',
                component: WorkOrderChargesPage,
                meta: {
                  breadcrumb: 'Work Order Charges'
                }
              }
            ]
          },
          {
            path: 'ODataApi',
            name: 'ODataApiOverview',
            routeName: 'Reporting Cloud',
            linkRoute: 'ODataGettingStarted',
            group: 'Resources',
            props: { isNew: 'true' },
            meta: {
              breadcrumb: 'Reporting Cloud'
            },
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                name: 'ODataGettingStarted',
                routeName: 'Getting Started ',
                linkRoute: 'ODataGettingStarted',
                group: 'Resources',
                component: ODataApiPage,
                isHashTag: true,
                children: [
                  {
                    path: '',
                    name: 'ODataGettingStarted',
                    group: 'Getting Started',
                    routeName: 'Getting Started',
                    linkRoute: 'getting-started',
                    meta: {
                      breadcrumb: 'Getting Started'
                    }
                  },
                  {
                    path: '',
                    group: 'Getting Started',
                    name: 'What is it?',
                    linkRoute: 'what-is-it'
                  },
                  {
                    path: '',
                    group: 'Getting Started',
                    name: 'Common usage scenarios',
                    linkRoute: 'common-usage'
                  },
                  {
                    path: '',
                    group: 'Getting Started',
                    name: 'How do I connect?',
                    linkRoute: 'how-connect'
                  },
                  {
                    path: '',
                    group: 'Getting Started',
                    name: 'Accessing from Power BI Desktop',
                    linkRoute: 'power-bi-desktop'
                  },
                  {
                    path: '',
                    group: 'Getting Started',
                    name: 'What you need',
                    linkRoute: 'what-you-need'
                  },
                  {
                    path: '',
                    group: 'Conventions',
                    linkRoute: 'conventions'
                  },
                  {
                    path: '',
                    group: 'Limitations',
                    linkRoute: 'limitations'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    linkRoute: 'entities'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'BillingLines',
                    linkRoute: 'billinglines'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Categories',
                    linkRoute: 'categories'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Certifications',
                    linkRoute: 'certifications'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Classes',
                    linkRoute: 'classes'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'CostCenters',
                    linkRoute: 'costcenters'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'CostCodes',
                    linkRoute: 'costcodes'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Customers',
                    linkRoute: 'customers'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Departments',
                    linkRoute: 'departments'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Descriptions',
                    linkRoute: 'descriptions'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'EmployeeCertifications',
                    linkRoute: 'employeecertifications'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'EmployeeTypes',
                    linkRoute: 'employeetypes'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Entities',
                    linkRoute: 'entities'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'InventoryKeys',
                    linkRoute: 'inventorykeys'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'InvoiceHeaders',
                    linkRoute: 'invoiceheaders'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'InvoiceLines',
                    linkRoute: 'invoicelines'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Items',
                    linkRoute: 'items'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Kits',
                    linkRoute: 'kits'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'LostAndFound',
                    linkRoute: 'lostandfound'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Manufacturers',
                    linkRoute: 'manufacturers'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'MeterTransactions',
                    linkRoute: 'metertransactions'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Models',
                    linkRoute: 'models'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'PickTicketHeaders',
                    linkRoute: 'pickticketheaders'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'PickTicketItemsPicked',
                    linkRoute: 'pickticketitemspicked'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'PickTicketItemsToPick',
                    linkRoute: 'pickticketitemstopick'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'RateSheetHeaders',
                    linkRoute: 'ratesheetheaders'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'RateSheetLines',
                    linkRoute: 'ratesheetlines'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Roles',
                    linkRoute: 'roles'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'RolePermissions',
                    linkRoute: 'rolePermissions'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'ServiceEventsDaily',
                    linkRoute: 'serviceeventsdaily'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'ServiceRequests',
                    linkRoute: 'servicerequests'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'ShippingMethods',
                    linkRoute: 'shippingmethods'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'StatusCodes',
                    linkRoute: 'statuscodes'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'TemplateHeaders',
                    linkRoute: 'templateheaders'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'TemplateLines',
                    linkRoute: 'templatelines'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'ToolBrowser',
                    linkRoute: 'toolbrowser'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'TransferHeaders',
                    linkRoute: 'transferheaders'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'TransferLines',
                    linkRoute: 'transferlines'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'UnitsOfMeasure',
                    linkRoute: 'unitsofmeasure'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Users',
                    linkRoute: 'users'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'UserPermissions',
                    linkRoute: 'userPermissions'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'UserRoles',
                    linkRoute: 'userRoles'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'VehicleInformation',
                    linkRoute: 'vehicleinformation'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Vendors',
                    linkRoute: 'vendors'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'Visibility',
                    linkRoute: 'visibility'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'WorkOrders',
                    linkRoute: 'workorders'
                  },
                  {
                    path: '',
                    group: 'Entities',
                    name: 'WorkOrdersCharges',
                    linkRoute: 'workordercharges'
                  }
                ]
              },
              {
                path: 'PowerBiQuickStart',
                group: 'Resources',
                name: 'ODataApiPowerBiQuickStart',
                routeName: 'Power BI Quick Start',
                linkRoute: 'ODataApiPowerBiQuickStart',
                component: PowerBiQuickStartPage,
                isHashTag: true,
                meta: {
                  breadcrumb: 'Power BI Quick Start'
                }
              }
            ]
          },
          {
            path: 'Integrations',
            name: 'Overview',
            routeName: 'Integrations',
            linkRoute: 'Overview',
            group: 'Resources',
            meta: {
              breadcrumb: 'Integrations'
            },
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                group: 'Introduction',
                name: 'Overview',
                routeName: 'Overview',
                linkRoute: 'Overview',
                component: OverviewPage,
                meta: {
                  breadcrumb: 'Getting Started'
                }
              },
              {
                path: 'Vista/Setup',
                group: 'Vista',
                name: 'VistaSetup',
                routeName: 'Setup',
                linkRoute: 'VistaSetup',
                component: VistaSetupPage,
                meta: {
                  breadcrumb: 'Vista'
                }
              },
              {
                path: 'Vista/DataMapping',
                group: 'Vista',
                name: 'VistaDataMapping',
                routeName: 'Data Mapping',
                linkRoute: 'VistaDataMapping',
                component: VistaDataMappingPage,
                meta: {
                  breadcrumb: 'Vista'
                }
              },
              {
                path: 'Vista/ImportingJobCosts',
                group: 'Vista',
                name: 'VistaImportingJobCosts',
                routeName: 'Importing Job Costs',
                linkRoute: 'VistaImportingJobCosts',
                component: ImportingJobCostsPage,
                meta: {
                  breadcrumb: 'Vista'
                }
              },
              {
                path: 'Vista/PurchaseOrder',
                group: 'Vista',
                name: 'VistaPurchaseOrder',
                routeName: 'Purchase Order',
                linkRoute: 'VistaPurchaseOrder',
                component: VistaPurchaseOrderPage,
                meta: {
                  breadcrumb: 'Vista'
                }
              },
              {
                path: 'Vista/Receipts',
                group: 'Vista',
                name: 'VistaReceipts',
                routeName: 'Receipts',
                linkRoute: 'VistaReceipts',
                component: VistaReceiptsPage,
                meta: {
                  breadcrumb: 'Vista'
                }
              },
              {
                path: 'Spectrum/Setup',
                group: 'Spectrum',
                name: 'SpectrumSetup',
                routeName: 'Setup',
                linkRoute: 'SpectrumSetup',
                component: SpectrumSetupPage,
                meta: {
                  breadcrumb: 'Spectrum'
                }
              },
              {
                path: 'Spectrum/DataMapping',
                group: 'Spectrum',
                name: 'SpectrumDataMapping',
                routeName: 'Data Mapping',
                linkRoute: 'SpectrumDataMapping',
                component: SpectrumDataMappingPage,
                meta: {
                  breadcrumb: 'Spectrum'
                }
              },
              {
                path: 'CMIC/Setup',
                group: 'CMiC',
                name: 'CMICSetup',
                routeName: 'Setup',
                linkRoute: 'CMICSetup',
                component: CMICSetupPage,
                meta: {
                  breadcrumb: 'CMiC'
                }
              },
              {
                path: 'CMIC/DataMapping',
                group: 'CMiC',
                name: 'CMICDataMapping',
                routeName: 'Data Mapping',
                linkRoute: 'CMICDataMapping',
                component: CMICDataMappingPage,
                meta: {
                  breadcrumb: 'CMiC'
                }
              },
              {
                path: 'CMIC/ImportingJobCosts',
                group: 'CMiC',
                name: 'CMICImportingJobCosts',
                routeName: 'Importing Job Costs',
                linkRoute: 'CMICImportingJobCosts',
                component: CMICImportingJobCostsPage,
                meta: {
                  breadcrumb: 'CMiC'
                }
              },
              {
                path: 'Sage300CRE/Setup',
                group: 'Sage 300 CRE',
                name: 'Sage300CRESetup',
                routeName: 'Setup',
                linkRoute: 'Sage300CRESetup',
                component: Sage300CRESetupPage,
                meta: {
                  breadcrumb: 'Sage 300 CRE'
                }
              },
              {
                path: 'Sage300CRE/DataMapping',
                group: 'Sage 300 CRE',
                name: 'Sage300CREDataMapping',
                routeName: 'Data Mapping',
                linkRoute: 'Sage300CREDataMapping',
                component: Sage300CREDataMappingPage,
                meta: {
                  breadcrumb: 'Sage 300 CRE'
                }
              },
              {
                path: 'Sage300CRE/ImportingJobCosts',
                group: 'Sage 300 CRE',
                name: 'Sage300CREImportingJobCosts',
                routeName: 'Importing Job Costs',
                linkRoute: 'Sage300CREImportingJobCosts',
                component: Sage300CREImportingJobCostsPage,
                meta: {
                  breadcrumb: 'Sage 300 CRE'
                }
              },
              {
                path: 'Sage100c/Setup',
                group: 'Sage 100 Contractor',
                name: 'Sage100cSetup',
                routeName: 'Setup',
                linkRoute: 'Sage100cSetup',
                component: Sage100cSetupPage,
                meta: {
                  breadcrumb: 'Sage 100 Contractor'
                }
              },
              {
                path: 'Sage100c/DataMapping',
                group: 'Sage 100 Contractor',
                name: 'Sage100cDataMapping',
                routeName: 'Data Mapping',
                linkRoute: 'Sage100cDataMapping',
                component: Sage100cDataMappingPage,
                meta: {
                  breadcrumb: 'Sage 100 Contractor'
                }
              },
              {
                path: 'SingleSignOn/SamlSetup',
                group: 'Single Sign On',
                name: 'SingleSignOnSamlSetup',
                routeName: 'SAML Setup',
                linkRoute: 'SingleSignOnSamlSetup',
                component: SingleSignOnSamlSetupPage,
                meta: {
                  breadcrumb: 'Single Sign On'
                }
              },
              {
                path: 'SingleSignOn/AzureADQuickStart',
                group: 'Single Sign On',
                name: 'SingleSignOnAzureADQuickStart',
                routeName: 'Azure AD Quick Start',
                linkRoute: 'SingleSignOnAzureADQuickStart',
                component: SingleSignOnAzureADQuickStartPage,
                meta: {
                  breadcrumb: 'Azure AD Quick Start'
                }
              },
              {
                path: 'eCMS/Setup',
                group: 'eCMS',
                name: 'ECMSSetup',
                routeName: 'Setup',
                linkRoute: 'ECMSSetup',
                component: ECMSSetupPage,
                meta: {
                  breadcrumb: 'eCMS'
                }
              },
              {
                path: 'eCMS/DataMapping',
                group: 'eCMS',
                name: 'ECMSDataMapping',
                routeName: 'Data Mapping',
                linkRoute: 'ECMSDataMapping',
                component: ECMSDataMappingPage,
                meta: {
                  breadcrumb: 'eCMS'
                }
              },
              {
                path: 'Procore/Setup',
                group: 'Procore',
                name: 'ProcoreSetup',
                routeName: 'Setup',
                linkRoute: 'ProcoreSetup',
                component: ProcoreSetupPage,
                meta: {
                  breadcrumb: 'Procore'
                }
              },
              {
                path: 'Procore/DataMapping',
                group: 'Procore',
                name: 'ProcoreDataMapping',
                routeName: 'Data Mapping',
                linkRoute: 'ProcoreDataMapping',
                component: ProcoreDataMappingPage,
                meta: {
                  breadcrumb: 'Procore'
                }
              },
              {
                path: 'DEWALT/Setup',
                group: 'DEWALT',
                name: 'DEWALTSetup',
                routeName: 'Setup',
                linkRoute: 'DEWALTSetup',
                component: DEWALTSetupPage,
                meta: {
                  breadcrumb: 'DEWALT'
                }
              },
              {
                path: 'DEWALT/DataMapping',
                group: 'DEWALT',
                name: 'DEWALTDataMapping',
                routeName: 'Data Mapping',
                linkRoute: 'DEWALTDataMapping',
                component: DEWALTDataMappingPage,
                meta: {
                  breadcrumb: 'DEWALT'
                }
              },
              {
                path: 'DEWALT/TrackingData',
                group: 'DEWALT',
                name: 'DEWALTTrackingData',
                routeName: 'Tracking Data',
                linkRoute: 'DEWALTTrackingData',
                component: DEWALTTrackingDataPage,
                meta: {
                  breadcrumb: 'DEWALT'
                }
              },
              {
                path: 'GeoTabs/Setup',
                group: 'GeoTabs',
                name: 'GeoTabsSetup',
                routeName: 'Setup',
                linkRoute: 'GeoTabsSetup',
                component: GeoTabsSetupPage,
                meta: {
                  breadcrumb: 'GeoTabs'
                }
              },
              {
                path: 'GeoTabs/DataMapping',
                group: 'GeoTabs',
                name: 'GeoTabsDataMapping',
                routeName: 'Data Mapping',
                linkRoute: 'GeoTabsDataMapping',
                component: GeoTabsDataMappingPage,
                meta: {
                  breadcrumb: 'GeoTabs'
                }
              },
              {
                path: 'GpsInsight/Setup',
                group: 'GPS Insight',
                name: 'GpsInsightSetup',
                routeName: 'Setup',
                linkRoute: 'GpsInsightSetup',
                component: GpsInsightSetupPage,
                meta: {
                  breadcrumb: 'GPS Insight'
                }
              },
              {
                path: 'GpsInsight/DataMapping',
                group: 'GPS Insight',
                name: 'GpsInsightDataMapping',
                routeName: 'Data Mapping',
                linkRoute: 'GpsInsightDataMapping',
                component: GpsInsightDataMappingPage,
                meta: {
                  breadcrumb: 'GPS Insight'
                }
              },
              {
                path: 'Intacct/Setup',
                group: 'Intacct',
                name: 'IntacctSetup',
                routeName: 'Setup',
                linkRoute: 'IntacctSetup',
                component: IntacctSetupPage,
                meta: {
                  breadcrumb: 'Intacct'
                }
              },
              {
                path: 'Intacct/DataMapping',
                group: 'Intacct',
                name: 'IntacctDataMapping',
                routeName: 'Data Mapping',
                linkRoute: 'IntacctDataMapping',
                component: IntacctDataMappingPage,
                meta: {
                  breadcrumb: 'Intacct'
                }
              }
            ]
          },
          {
            path: 'Compliance',
            name: 'ComplianceIntroduction',
            routeName: 'IT Compliance',
            linkRoute: 'ComplianceIntroduction',
            group: 'Resources',
            meta: {
              breadcrumb: 'Compliance'
            },
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                group: 'Compliance',
                name: 'ComplianceIntroduction',
                routeName: 'Introduction',
                linkRoute: 'ComplianceIntroduction',
                component: ComplianceIntroductionPage,
                meta: {
                  breadcrumb: 'Introduction'
                }
              },
              {
                path: 'SystemArchitecture',
                group: 'Compliance',
                name: 'System Architecture',
                linkRoute: 'System Architecture',
                component: ComplianceSystemArchitecturePage,
                meta: {
                  breadcrumb: 'System Architecture'
                }
              },
              {
                path: 'BackupRestore',
                group: 'Compliance',
                name: 'Backup And Restore',
                linkRoute: 'Backup And Restore',
                component: ComplianceBackupRestorePage,
                meta: {
                  breadcrumb: 'Backup And Restore'
                }
              },
              {
                path: 'NetworkRequirements',
                group: 'Compliance',
                name: 'Network Requirements',
                linkRoute: 'Network Requirements',
                component: ComplianceNetworkRequirementsPage,
                meta: {
                  breadcrumb: 'Network Requirements'
                }
              },
              {
                path: 'FAQ',
                group: 'Compliance',
                name: 'FAQ',
                linkRoute: 'FAQ',
                component: ComplianceFAQPage,
                meta: {
                  breadcrumb: 'FAQ'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'Cloud',
        name: 'CloudGettingStarted',
        routeName: 'Cloud User Guide',
        linkRoute: 'CloudGettingStarted',
        group: 'Home',
        component: CloudUserGuidePage,
        meta: {
          breadcrumb: 'Cloud User Guide'
        },
        isHashTag: true,
        children: [
          {
            path: '',
            group: 'The Platform',
            name: 'Overview',
            linkRoute: 'overview'
          },
          {
            path: '',
            group: 'The Platform',
            name: 'Logging into the Platform',
            linkRoute: 'logging-into-the-platform'
          },
          {
            path: '',
            group: 'The Platform',
            name: 'Single Sign On',
            linkRoute: 'single-sign-on'
          },
          {
            path: '',
            group: 'The Platform',
            name: 'Appendix',
            linkRoute: 'appendix'
          },
          {
            path: '',
            group: 'Administration',
            linkRoute: 'administration'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Company Settings',
            linkRoute: 'company-settings'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Uploading a Company Logo',
            linkRoute: 'uploading-a-company-logo'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Login',
            linkRoute: 'login'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Tool & Equipment Aging Periods',
            linkRoute: 'tool-equipment-aging-periods'
          },
          {
            path: '',
            group: 'Administration',
            name: 'List Items',
            linkRoute: 'list-items'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Creating a New List Item',
            linkRoute: 'creating-a-new-list-item'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Filter List Items',
            linkRoute: 'filter-list-items'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Status Codes',
            linkRoute: 'status-codes'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Data Merge Utility ',
            linkRoute: 'Data Merge Utility '
          },
          {
            path: '',
            group: 'Administration',
            name: 'Using the Data Merge Utility',
            linkRoute: 'using-the-data-merge-utility'
          },
          {
            path: '',
            group: 'Administration',
            name: 'The Organization Tree',
            linkRoute: 'the-organization-tree'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Org Tree Explained',
            linkRoute: 'org-tree-explained'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Locations',
            linkRoute: 'locations'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Stock Locations',
            linkRoute: 'stock-locations'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Creating a Stock Location',
            linkRoute: 'creating-a-stock-location'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Job (Non-Stock) Locations',
            linkRoute: 'job-non-stock-Locations'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Modifying Visibility',
            linkRoute: 'modifying-visibility'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Inactivating a Location/Stopping Transfers to a Location',
            linkRoute: 'inactivating-a-location/stopping-transfers-to-a-location'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Employees and Permissions',
            linkRoute: 'employees-and-permissions'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Types of Employees',
            linkRoute: 'types-of-employees'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Creating a Standard Employee',
            linkRoute: 'creating-a-standard-employee'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Users',
            linkRoute: 'users'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Creating a User',
            linkRoute: 'creating-a-user'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Managing Existing Users',
            linkRoute: 'managing-existing-users'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Roles Management',
            linkRoute: 'roles-management'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Create a New User Role',
            linkRoute: 'create-a-new-user-role'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Manage User Roles',
            linkRoute: 'manage-user-roles'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Setting Up User Notifications',
            linkRoute: 'setting-up-user-notifications'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Set up SMS Notifications',
            linkRoute: 'set-up-sms-notifications'
          },
          {
            path: '',
            group: 'Administration',
            name: 'User Roles',
            linkRoute: 'user-roles'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Numbering',
            linkRoute: 'numbering'
          },
          {
            path: '',
            group: 'Administration',
            name: 'System Numbering and User Numbering',
            linkRoute: 'system-numbering-and-user-numbering'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Descriptions',
            linkRoute: 'descriptions'
          },
          {
            path: '',
            group: 'Administration',
            name: 'Managing Descriptions',
            linkRoute: 'managing-descriptions'
          },
          {
            path: '',
            group: 'Integration Catalog',
            linkRoute: 'integration-catalog'
          },
          {
            path: '',
            group: 'Integration Catalog',
            name: 'Settings',
            linkRoute: 'integration-settings'
          },
          {
            path: '',
            group: 'Product Catalog',
            linkRoute: 'product-catalog'
          },
          {
            path: '',
            group: 'Product Catalog',
            name: 'Catalog Records',
            linkRoute: 'catalog-records'
          },
          {
            path: '',
            group: 'Product Catalog',
            name: 'Adding Catalog Records',
            linkRoute: 'adding-catalog-records'
          },
          {
            path: '',
            group: 'Product Catalog',
            name: 'Adding Catalog Records Manually',
            linkRoute: 'adding-catalog-records-manually'
          },
          {
            path: '',
            group: 'Product Catalog',
            name: 'Updating Catalog Records',
            linkRoute: 'updating-catalog-records'
          },
          {
            path: '',
            group: 'Product Catalog',
            name: 'Add an Image to a Catalog Record',
            linkRoute: 'add-an-image-to-a-catalog-Record'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            linkRoute: 'job-cost-and-billing'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Introduction',
            linkRoute: 'job-cost-billing-introduction'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'The Company Calendar',
            linkRoute: 'the-company-calendar'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Set Appointment',
            linkRoute: 'set-appointment'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Set Holidays and Modify Working Hours',
            linkRoute: 'set-holidays-and-modify-working-hours'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Cost Centers and Cost Codes',
            linkRoute: 'cost-centers-and-cost-codes'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Creating a Cost Code',
            linkRoute: 'creating-a-cost-code'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Editing a Cost Code',
            linkRoute: 'editing-a-cost-code'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Associating Cost Codes and Cost Centers',
            linkRoute: 'associating-cost-codes-and-cost-centers'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Associating Cost Code Groups to Cost Centers',
            linkRoute: 'associating-cost-code-groups-to-cost-centers'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Creating a Cost Center',
            linkRoute: 'creating-a-cost-center'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'General Info Tab',
            linkRoute: 'general-info-tab'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Attaching Rate Sheets to a Cost Center',
            linkRoute: 'attaching-rate-sheets-to-a-cost-center'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Project Billing Rules',
            linkRoute: 'project-billing-rules'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Editing the Project Calendar',
            linkRoute: 'editing-the-project-calendar'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Tools Assigned or Returned Rules',
            linkRoute: 'tools-assigned-or-returned-rules'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Establishing Default Project Hours for a Particular Cost Center',
            linkRoute: 'establishing-default-project-hours-for-a-particular-cost-center'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Removing Cost Centers',
            linkRoute: 'removing-cost-centers'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Rate Sheets',
            linkRoute: 'rate-sheets'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'The Rate Sheet User Interface',
            linkRoute: 'the-rate-sheet-user-interface'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Rate Sheet Tabs',
            linkRoute: 'rate-sheet-tabs'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Line Items Load Dynamically',
            linkRoute: 'line-items-load-dynamically'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Item Base Value',
            linkRoute: 'item-base-value'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Navigation Tips',
            linkRoute: 'navigation-tips'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Rate Migration',
            linkRoute: 'rate-migration'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Rate Sheet Rules',
            linkRoute: 'rate-sheet-rules'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Migration Points',
            linkRoute: 'migration-points'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Calculation Methods',
            linkRoute: 'calculation-methods'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Assignment Method',
            linkRoute: 'assignment-method'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Calendar Method',
            linkRoute: 'calendar-method'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Hourly Usage Method',
            linkRoute: 'hourly-usage-method'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Calculation Method Groupings',
            linkRoute: 'calculation-method-groupings'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Column Headings',
            linkRoute: 'column-headings'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Non-Grouping Headings',
            linkRoute: 'non-grouping-headings'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Required Grouping Headings',
            linkRoute: 'required-grouping-headings'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Optional Grouping Headings',
            linkRoute: 'optional-grouping-headings'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Calculation Method Available Rate Sheet Columns',
            linkRoute: 'calculation-method-available-rate-sheet-columns'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Creating Rate Sheets',
            linkRoute: 'creating-rate-sheets'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Creating a Tools & Equipment Rate Sheet',
            linkRoute: 'creating-a-tools-equipment-rate-sheet'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Creating a Materials & Consumables Rate Sheet',
            linkRoute: 'creating-a-materials-consumables-rate-sheet'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Copying a Rate Sheet',
            linkRoute: 'copying-a-rate-sheet'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Deleting a Rate Sheet',
            linkRoute: 'deleting-a-rate-sheet'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Publishing to Excel',
            linkRoute: 'publishing-to-excel'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Importing from Excel',
            linkRoute: 'importing-from-excel'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Initiating Job Cost & Billing Charges',
            linkRoute: 'initiating-job-cost-billing-charges'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Three Rules for Initiating Charges',
            linkRoute: 'three-rules-for-initiating-charges'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Cost Centers, Cost Codes & Transfer Tickets',
            linkRoute: 'cost-centers-cost-codes-transfer-tickets'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Editing Transfers Using Job Cost & Billing Information',
            linkRoute: 'editing-transfers-using-job-cost-billing-information'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Billing Streams',
            linkRoute: 'billing-streams'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Billing Stream Management',
            linkRoute: 'billing-stream-management'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'The Billing Browser',
            linkRoute: 'the-billing-browser'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'The Billing Browser Interface',
            linkRoute: 'the-billing-browser-interface'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Cost Centers',
            linkRoute: 'cost-centers'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'View Details',
            linkRoute: 'view-details'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Hold',
            linkRoute: 'hold'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Header Buttons',
            linkRoute: 'header-buttons'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Billing Browser Workflow',
            linkRoute: 'billing-browser-workflow'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Calculating Transactions',
            linkRoute: 'calculating-transactions'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Hourly Usage',
            linkRoute: 'hourly-usage'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'The Equipment Usage Log',
            linkRoute: 'the-equipment-usage-log'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Adding a Related Charge',
            linkRoute: 'adding-a-related-charge'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Calculating Un-Posted Charges',
            linkRoute: 'calculating-un-posted-charges'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'The Transaction Detail Window',
            linkRoute: 'the-transaction-detail-window'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Stopping Job Cost & Billing Charges',
            linkRoute: 'stopping-job-cost-billing-charges'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Adjusting Un-Posted Charges',
            linkRoute: 'adjusting-un-posted-charges'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Posting Job Cost & Billing Transactions',
            linkRoute: 'posting-job-cost-billing-transactions'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Exporting Job Cost & Billing Data',
            linkRoute: 'exporting-job-cost-billing-data'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Export Template Types',
            linkRoute: 'export-template-types'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Transaction Detail Export Templates',
            linkRoute: 'transaction-detail-export-templates'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Exporting Data Using a Custom Export Template',
            linkRoute: 'exporting-data-using-a-custom-export-template'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Invoice Browser',
            linkRoute: 'invoice-browser'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Generating Invoices',
            linkRoute: 'generating-invoices'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Invoices',
            linkRoute: 'invoices'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Invoice Options Tab',
            linkRoute: 'invoice-options-tab'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Printing Invoices',
            linkRoute: 'printing-invoices'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Job Cost & Billing Reporting Options',
            linkRoute: 'job-cost-billing-reporting-options'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'ROI Report',
            linkRoute: 'roi-report'
          },
          {
            path: '',
            group: 'Job Cost and Billing',
            name: 'Billing Comparison Report',
            linkRoute: 'billing-comparison-report'
          },

          {
            path: '',
            group: 'Reports & Graphs',
            linkRoute: 'reports-graphs'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Reporting from the Reports & Graphs Module',
            linkRoute: 'reporting-from-the-reports-graphs-module'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Survey of Key Reports',
            linkRoute: 'survey-of-key-reports'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Tool Reports Group',
            linkRoute: 'tool-reports-group'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'List Reports Group',
            linkRoute: 'list-reports-group'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Transfer Reports Group',
            linkRoute: 'transfer-reports-group'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Bar Code Labels and Badges Group',
            linkRoute: 'bar-code-labels-and-badges-group'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Consumables Reports Group',
            linkRoute: 'consumables-reports-group'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Purchasing Reports Group',
            linkRoute: 'purchasing-reports-group'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Service Reports Group',
            linkRoute: 'service-reports-group'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Billing Reports Group',
            linkRoute: 'billing-reports-group'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Custom Reports',
            linkRoute: 'custom-reports'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Building a Custom Report',
            linkRoute: 'building-a-custom-report'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Editing a Custom Report',
            linkRoute: 'editing-a-custom-report'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Scheduled Reports',
            linkRoute: 'scheduled-reports'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Creating a New Scheduled Report',
            linkRoute: 'creating-a-new-scheduled-report'
          },
          {
            path: '',
            group: 'Reports & Graphs',
            name: 'Editing & Deleting Scheduled Reports',
            linkRoute: 'editing-deleting-scheduled-reports'
          },
          {
            path: '',
            group: 'Requisitions',
            linkRoute: 'requisitions'
          },
          {
            path: '',
            group: 'Requisitions',
            name: 'How to Use Requisitions',
            linkRoute: 'how-to-use-requisitions'
          },
          {
            path: '',
            group: 'Requisitions',
            name: 'Home Screen',
            linkRoute: 'home-screen'
          },
          {
            path: '',
            group: 'Requisitions',
            name: 'Open',
            linkRoute: 'open'
          },
          {
            path: '',
            group: 'Requisitions',
            name: 'By Item',
            linkRoute: 'by-item'
          },
          {
            path: '',
            group: 'Requisitions',
            name: 'By Requisition',
            linkRoute: 'by-requisition'
          },
          {
            path: '',
            group: 'Requisitions',
            name: 'Delivered',
            linkRoute: 'delivered'
          },
          {
            path: '',
            group: 'Requisitions',
            name: 'Request Again',
            linkRoute: 'request-again'
          },
          {
            path: '',
            group: 'Requisitions',
            name: 'Shopping Cart',
            linkRoute: 'shopping-cart'
          },
          {
            path: '',
            group: 'Requisitions',
            name: 'Processing Requests',
            linkRoute: 'processing-requests'
          },
          {
            path: '',
            group: 'Returns',
            linkRoute: 'returns'
          },
          {
            path: '',
            group: 'Returns',
            name: 'Using Returns',
            linkRoute: 'using-returns'
          },
          {
            path: '',
            group: 'Returns',
            name: 'Returns Home',
            linkRoute: 'returns-home'
          },
          {
            path: '',
            group: 'Returns',
            name: 'Start a Return',
            linkRoute: 'start-a-return'
          },
          {
            path: '',
            group: 'Returns',
            name: 'Returns in Progress',
            linkRoute: 'returns-in-progress'
          },
          {
            path: '',
            group: 'Returns',
            name: 'Recently Returned',
            linkRoute: 'recently-returned'
          },
          {
            path: '',
            group: 'Returns',
            name: 'Submitting a Return Ticket',
            linkRoute: 'submitting-a-return-ticket'
          },
          {
            path: '',
            group: 'Returns',
            name: 'Processing Returns',
            linkRoute: 'processing-returns'
          },
          {
            path: '',
            group: 'Returns',
            name: 'Returns History',
            linkRoute: 'returns-history'
          },
          {
            path: '',
            group: 'Job Stock',
            linkRoute: 'job-stock'
          },
          {
            path: '',
            group: 'Job Stock',
            name: 'Job Stock Browser',
            linkRoute: 'job-stock-browser'
          },
          {
            path: '',
            group: 'Job Stock',
            name: 'Job Level Manager',
            linkRoute: 'job-level-manager'
          },
          {
            path: '',
            group: 'Item Browser',
            linkRoute: 'item-browser'
          },
          {
            path: '',
            group: 'Item Browser',
            name: 'Using the Item Browser',
            linkRoute: 'using-the-item-browser'
          },
          {
            path: '',
            group: 'Item Browser',
            name: 'Item Browser Details',
            linkRoute: 'item-browser-details'
          },
          {
            path: '',
            group: 'Item Browser',
            name: 'Tool Browser',
            linkRoute: 'tool-browser'
          },
          {
            path: '',
            group: 'Item Browser',
            name: 'Material Browser',
            linkRoute: 'material-browser'
          },
          {
            path: '',
            group: 'Item Browser',
            name: 'Sorting Tool/Material Records',
            linkRoute: 'sorting-tool/material-records'
          },
          {
            path: '',
            group: 'Item Browser',
            name: 'Advanced Search',
            linkRoute: 'advanced-search'
          },
          {
            path: '',
            group: 'Item Browser',
            name: 'Printing Search Results',
            linkRoute: 'printing-search-results'
          },
          {
            path: '',
            group: 'Item Browser',
            name: 'Retiring Tools',
            linkRoute: 'retiring-tools'
          },
          {
            path: '',
            group: 'Item Browser',
            name: 'Reinstating a Retired Tool',
            linkRoute: 'reinstating-a-retired-tool'
          },
          {
            path: '',
            group: 'Item Browser',
            name: 'Low Level Browser',
            linkRoute: 'low-level-browser'
          },
          {
            path: '',
            group: 'Requisition Browser',
            linkRoute: 'requisition-browser'
          },
          {
            path: '',
            group: 'Requisition Browser',
            name: 'Requisition Browser',
            linkRoute: 'requisition-browser'
          },
          {
            path: '',
            group: 'Requisition Browser',
            name: 'Company Settings',
            linkRoute: 'company-setting'
          },
          {
            path: '',
            group: 'Requisition Browser',
            name: 'Permissions',
            linkRoute: 'permissions'
          },
          {
            path: '',
            group: 'Requisition Browser',
            name: 'Using the Requisition Browser',
            linkRoute: 'using-requisition-browser'
          },
          {
            path: '',
            group: 'Requisition Browser',
            name: 'Requisition Action View',
            linkRoute: 'requisition-action-view'
          },
          {
            path: '',
            group: 'Pick Tickets & Transfers',
            linkRoute: 'pick-tickets-transfers'
          },
          {
            path: '',
            group: 'Pick Tickets & Transfers',
            name: 'Pick Tickets',
            linkRoute: 'pick-tickets'
          },
          {
            path: '',
            group: 'Pick Tickets & Transfers',
            name: 'Pick Ticket Line Information',
            linkRoute: 'pick-ticket-line-information'
          },
          {
            path: '',
            group: 'Pick Tickets & Transfers',
            name: 'Pick Ticket Header Information',
            linkRoute: 'pick-ticket-header-information'
          },
          {
            path: '',
            group: 'Pick Tickets & Transfers',
            name: 'Creating a Pick Ticket',
            linkRoute: 'creating-a-pick-ticket'
          },
          {
            path: '',
            group: 'Pick Tickets & Transfers',
            name: 'Adding Items to a Pick Ticket',
            linkRoute: 'adding-items-to-a-pick-ticket'
          },
          {
            path: '',
            group: 'Pick Tickets & Transfers',
            name: 'Editing a Pick Ticket',
            linkRoute: 'editing-a-pick-ticket'
          },
          {
            path: '',
            group: 'Pick Tickets & Transfers',
            name: 'Searching for Pick Tickets',
            linkRoute: 'searching-for-pick-tickets'
          },
          {
            path: '',
            group: 'Pick Tickets & Transfers',
            name: 'Viewing a Pick Ticket',
            linkRoute: 'viewing-a-pick-ticket'
          },
          {
            path: '',
            group: 'Pick Tickets & Transfers',
            name: 'Deleting a Pick Ticket',
            linkRoute: 'deleting-a-pick-ticket'
          },
          {
            path: '',
            group: 'Pick Tickets & Transfers',
            name: 'Picking a Pick Ticket',
            linkRoute: 'picking-a-pick-ticket'
          },
          {
            path: '',
            group: 'Transfers',
            linkRoute: 'transfers'
          },
          {
            path: '',
            group: 'Transfers',
            name: 'Transfer Ticket Line Information',
            linkRoute: 'transfer-ticket-line-information'
          },
          {
            path: '',
            group: 'Transfers',
            name: 'Transfer Ticket Header Information',
            linkRoute: 'transfer-ticket-header-information'
          },
          {
            path: '',
            group: 'Transfers',
            name: 'Creating Transfers',
            linkRoute: 'creating-transfers'
          },
          {
            path: '',
            group: 'Transfers',
            name: 'Creating a Transfer from the Tool Browser',
            linkRoute: 'creating-a-transfer-from-the-tool-browser'
          },
          {
            path: '',
            group: 'Transfers',
            name: 'Creating a Transfer from the Material Browser',
            linkRoute: 'creating-a-transfer-from-the-material-browser'
          },
          {
            path: '',
            group: 'Transfers',
            name: 'Adding Tools or Materials to a Transfer Ticket',
            linkRoute: 'adding-tools-or-materials-to-a-transfer-ticket'
          },
          {
            path: '',
            group: 'Transfers',
            name: 'Assigning a Tool as Personal Issue (PI)',
            linkRoute: 'assigning-a-tool-as-personal-issue-(pi)'
          },
          {
            path: '',
            group: 'Transfers',
            name: 'Searching for and Viewing Transfer Tickets',
            linkRoute: 'searching-for-and-viewing-transfer-tickets'
          },
          {
            path: '',
            group: 'Transfers',
            name: 'Editing Transfer Tickets',
            linkRoute: 'editing-transfer-tickets'
          },
          {
            path: '',
            group: 'Transfers',
            name: 'Printing Transfers',
            linkRoute: 'printing-transfers'
          },
          {
            path: '',
            group: 'Transfers',
            name: 'Express Check-In',
            linkRoute: 'express-check-in'
          },
          {
            path: '',
            group: 'Transfers',
            name: 'Express Check-Out',
            linkRoute: 'express-check-out'
          },
          {
            path: '',
            group: 'Kits and Templates',
            linkRoute: 'kits-and-templates'
          },
          {
            path: '',
            group: 'Kits and Templates',
            name: 'Kits',
            linkRoute: 'kits'
          },
          {
            path: '',
            group: 'Kits and Templates',
            name: 'Creating a Kit',
            linkRoute: 'creating-a-kit'
          },
          {
            path: '',
            group: 'Kits and Templates',
            name: 'Finding a Kit',
            linkRoute: 'finding-a-kit'
          },
          {
            path: '',
            group: 'Kits and Templates',
            name: 'Transferring a Kit',
            linkRoute: 'transferring-a-kit'
          },
          {
            path: '',
            group: 'Kits and Templates',
            name: 'Templates',
            linkRoute: 'templates'
          },
          {
            path: '',
            group: 'Kits and Templates',
            name: 'Creating a Template',
            linkRoute: 'creating-a-template'
          },
          {
            path: '',
            group: 'Kits and Templates',
            name: 'Copying a Template',
            linkRoute: 'copying-a-template'
          },
          {
            path: '',
            group: 'Kits and Templates',
            name: 'Adding a Template to a Pick Ticket',
            linkRoute: 'adding-a-template-to-a-pick-ticket'
          },
          {
            path: '',
            group: 'Count Inventory',
            linkRoute: 'count-inventory'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Creating a Count Ticket',
            linkRoute: 'creating-a-count-ticket'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Count Ticket Details',
            linkRoute: 'count-ticket-details'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Viewing a Count Ticket',
            linkRoute: 'viewing-a-count-ticket'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Editing a Count Ticket',
            linkRoute: 'editing-a-count-ticket'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Printing a Count Ticket',
            linkRoute: 'printing-a-count-ticket'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Using Count Tickets',
            linkRoute: 'using-count-tickets'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Inventory for a Location',
            linkRoute: 'inventory-for-a-location'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Using an Existing Count Ticket to Inventory a Location',
            linkRoute: 'using-an-existing-count-ticket-to-inventory-a-location'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'The Inventory Adjustments Browser',
            linkRoute: 'the-inventory-adjustments-browser'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Viewing All Count Tickets',
            linkRoute: 'viewing-all-count-tickets'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Viewing Counted vs Uncounted Items',
            linkRoute: 'viewing-counted-vs-uncounted-items'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Using Inventory Adjustments to View Multiple Count Results',
            linkRoute: 'using-inventory-adjustments-to-view-multiple-count-results'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Posting Count Results',
            linkRoute: 'posting-count-results'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Count Inventory Reports',
            linkRoute: 'count-inventory-reports'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Physical Inventory Count Sheet',
            linkRoute: 'physical-inventory-count-sheet'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Count Ticket Report',
            linkRoute: 'count-ticket-report'
          },
          {
            path: '',
            group: 'Count Inventory',
            name: 'Count Inventory Project Report',
            linkRoute: 'count-inventory-project-report'
          },
          {
            path: '',
            group: 'Service & Calibration',
            linkRoute: 'service-calibration'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Service Plans',
            linkRoute: 'service-plans'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Creating a Service Plan for a Tool Model Record',
            linkRoute: 'creating-a-service-plan-for-a-tool-model-record'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Creating a Recurring Service Plan',
            linkRoute: 'creating-a-recurring-service-plan'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Work Order Details Tab',
            linkRoute: 'work-order-details-tab'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Tasks Tab',
            linkRoute: 'tasks-tab'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Creating a Service Task',
            linkRoute: 'creating-a-service-task'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Create an Inspection Task',
            linkRoute: 'create-an-inspection-task'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Create a Calibration Task',
            linkRoute: 'create-a-calibration-task'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Creating Calibration Specs',
            linkRoute: 'creating-calibration-specs'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Adding Calibration Specs as Calibration Tasks',
            linkRoute: 'adding-calibration-specs-as-calibration-tasks'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Instructions Tab',
            linkRoute: 'instructions-tab'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Required Tools Tab',
            linkRoute: 'required-tools-tab'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Adding Repair Parts to a Model Record',
            linkRoute: 'adding-repair-parts-to-a-model-record'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Parts Tab',
            linkRoute: 'parts-tab'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Charges Tab',
            linkRoute: 'charges-tab'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Adding a Charge to a Service Plan',
            linkRoute: 'adding-a-charge-to-a-service-plan'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Adding an Existing Service Plan to a Tool Record',
            linkRoute: 'adding-an-existing-service-plan-to-a-tool-record'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Adding a Unique Service Plan to an Individual Tool Record',
            linkRoute: 'adding-a-unique-service-plan-to-an-individual-tool-record'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Service Schedule',
            linkRoute: 'service-schedule'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Browsing the Service Schedule',
            linkRoute: 'browsing-the-service-schedule'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Printing the Service Schedule',
            linkRoute: 'printing-the-service-schedule'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Meter/Mileage Readings',
            linkRoute: 'meter/mileage-readings'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Setting a Meter Reading Interval',
            linkRoute: 'setting-a-meter-reading-interval'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Import Meter/Mileage Readings',
            linkRoute: 'import-meter/mileage-readings'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Importing Meter/Mileage Information',
            linkRoute: 'importing-meter/mileage-information'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Meter Exception Report',
            linkRoute: 'meter-exception-report'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'View Meter History',
            linkRoute: 'view-meter-history'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Service Requests & Work Orders',
            linkRoute: 'service-requests-&-work-orders'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Creating a Service Request',
            linkRoute: 'creating-a-service-request'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Creating a Service Request from the Navigator Sidebar',
            linkRoute: 'creating-a-service-request-from-the-navigator-side-bar'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Creating a Service Request from the Item Browser',
            linkRoute: 'creating-a-service-request-from-the-item-browser'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Converting a Service Request into a Work Order',
            linkRoute: 'converting-a-service-request-into-a-work-order'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Creating a Work Order',
            linkRoute: 'creating-a-work-order'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Viewing a Work Order',
            linkRoute: 'viewing-a-work-order'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Editing a Work Order',
            linkRoute: 'editing-a-work-order'
          },
          {
            path: '',
            group: 'Service & Calibration',
            name: 'Completing a Work Order',
            linkRoute: 'completeing-a-work-order'
          },
          {
            path: '',
            group: 'Purchasing',
            linkRoute: 'purchasing'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Purchasing & Receiving',
            linkRoute: 'purchasing-receiving'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Purchase Order Explained',
            linkRoute: 'purchase-order-explained'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Purchase Order Headers Explained',
            linkRoute: 'purchase-order-headers-explained'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Creating a Purchase Order',
            linkRoute: 'creating-a-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Creating a New Purchase Order',
            linkRoute: 'creating-a-new-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Adding Purchase Order Details',
            linkRoute: 'adding-purchase-order-details'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Adding Items to a Purchase Order',
            linkRoute: 'adding-items-to-a-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Adding a Tool to a Purchase Order',
            linkRoute: 'adding-a-tool-to-a-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Adding Materials to a Purchase Order',
            linkRoute: 'adding-materials-to-a-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Adding a New Item to a Purchase Order',
            linkRoute: 'adding-a-new-item-to-a-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Attaching Documents to a Purchase Order',
            linkRoute: 'attaching-documents-to-a-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Templates',
            linkRoute: 'templates'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Creating a Template',
            linkRoute: 'creating-a-template'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Adding a Template to a Purchase Order',
            linkRoute: 'adding-a-template-to-a-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Creating a Purchase Order from the Low-Level Browser',
            linkRoute: 'creating-a-purchase-order-from-the-low-level-browser'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Editing Purchase Orders',
            linkRoute: 'editing-purchase-orders'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Purchase Order Browser',
            linkRoute: 'purchase-order-browser'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Purchase Order Browser Details',
            linkRoute: 'purchase-order-browser-details'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Viewing an Existing Purchase Order',
            linkRoute: 'viewing-an-existing-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Deleting a Purchase Order',
            linkRoute: 'deleting-a-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Printing a Purchase Order',
            linkRoute: 'printing-a-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Receiving Tickets',
            linkRoute: 'receiving-tickets'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Receiving Ticket Explained',
            linkRoute: 'receiving-ticket-explained'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Opening a Receiving Ticket',
            linkRoute: 'opening-a-receiving-ticket'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Receiving Items from a Purchase Order',
            linkRoute: 'receiving-items-from-a-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Cancel Items on an Open Purchase Order',
            linkRoute: 'cancel-items-on-an-open-purchase-order'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'New Tool Wizard',
            linkRoute: 'new-tool-wizard'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Viewing a Receiving Ticket',
            linkRoute: 'viewing-a-receiving-ticket'
          },
          {
            path: '',
            group: 'Purchasing',
            name: 'Print a Receiving Ticket',
            linkRoute: 'print-a-receiving-ticket'
          }
        ]
      },
      {
        path: 'Mobile',
        name: 'MobileGettingStarted',
        routeName: 'Mobile User Guide',
        linkRoute: 'MobileGettingStarted',
        group: 'Home',
        component: MobileUserGuidePage,
        meta: {
          breadcrumb: 'Mobile User Guide'
        },
        isHashTag: true,
        children: [
          {
            path: '',
            name: 'MobileGettingStarted',
            group: 'Getting Started',
            routeName: 'Mobile',
            linkRoute: 'getting-started',
            meta: {
              breadcrumb: 'Getting Started'
            }
          },
          {
            path: '',
            group: 'Field Mobile',
            linkRoute: 'toolWatch-field-mobile'
          },
          {
            path: '',
            group: 'Field Introduction',
            groupName: 'Introduction',
            linkRoute: 'field-introduction'
          },
          {
            path: '',
            group: 'Field Introduction',
            groupName: 'Introduction',
            children: [
              {
                path: '',
                group: 'Installation and Login',
                linkRoute: 'field-installation-and-login'
              }
            ]
          },
          {
            path: '',
            group: 'Field Introduction',
            groupName: 'Introduction',
            children: [
              {
                path: '',
                group: 'Licensing Requirements',
                linkRoute: 'field-licensing-requirements'
              }
            ]
          },
          {
            path: '',
            group: 'Field Menu Screen',
            groupName: 'Menu Screen',
            linkRoute: 'field-menu-screen'
          },
          {
            path: '',
            group: 'Field Menu Screen',
            children: [
              {
                path: '',
                group: 'Settings',
                linkRoute: 'field-settings'
              }
            ]
          },
          {
            path: '',
            group: 'Field Menu Screen',
            children: [
              {
                path: '',
                group: 'Search',
                linkRoute: 'field-search'
              },
              {
                path: '',
                group: 'Search',
                name: 'Performing a manual search',
                linkRoute: 'field-performing-a-manual-search'
              }
            ]
          },
          {
            path: '',
            group: 'Field Menu Screen',
            children: [
              {
                path: '',
                group: 'Move',
                linkRoute: 'field-move'
              },
              {
                path: '',
                group: 'Move',
                name: 'Creating a Move Ticket',
                linkRoute: 'field-creating-a-move-ticket'
              },
              {
                path: '',
                group: 'Move',
                name: 'Adding Items to a Move Ticket',
                linkRoute: 'field-adding-items-to-a-move-ticket'
              },
              {
                path: '',
                group: 'Move',
                name: 'Submitting a Move Ticket',
                linkRoute: 'field-submitting-a-move-ticket'
              }
            ]
          },
          {
            path: '',
            group: 'Field Menu Screen',
            children: [
              {
                path: '',
                group: 'Request',
                linkRoute: 'field-request'
              },
              {
                path: '',
                group: 'Request',
                name: 'Creating a Request',
                linkRoute: 'field-creating-a-request'
              },
              {
                path: '',
                group: 'Request',
                name: 'Adding Items to a Request',
                linkRoute: 'field-adding-items-to-a-request'
              },
              {
                path: '',
                group: 'Request',
                name: 'Submitting/Saving/Deleting a Request',
                linkRoute: 'field-submitting-saving-deleting-a-request'
              },
              {
                path: '',
                group: 'Request',
                name: 'Processing Requests in Enterprise',
                linkRoute: 'field-processing-requests-in-enterprise'
              },
              {
                path: '',
                group: 'Request',
                name: 'Checking the Status of a Request',
                linkRoute: 'field-checking-the-status-of-a-request'
              }
            ]
          },
          {
            path: '',
            group: 'Field Menu Screen',
            children: [
              {
                path: '',
                group: 'Verify',
                linkRoute: 'field-verify'
              },
              {
                path: '',
                group: 'Verify',
                name: 'Creating a Verify Ticket',
                linkRoute: 'field-creating-a-verify-ticket'
              },
              {
                path: '',
                group: 'Verify',
                name: 'Verifying Unique Tools',
                linkRoute: 'field-verifying-unique-tools'
              },
              {
                path: '',
                group: 'Verify',
                name: 'Verifying Quantity Tools',
                linkRoute: 'field-verifying-quantity-tools'
              },
              {
                path: '',
                group: 'Verify',
                name: 'Add Additional Counted Items on Android Devices',
                linkRoute: 'field-add-additional-counted-items-on-android-devices'
              },
              {
                path: '',
                group: 'Verify',
                name: 'Add Additional Counted Items on iOS Devices',
                linkRoute: 'field-add-additional-counted-items-on-ios-devices'
              }
            ]
          },
          {
            path: '',
            group: 'Field Menu Screen',
            children: [
              {
                path: '',
                group: 'Receiving',
                linkRoute: 'field-receiving'
              },
              {
                path: '',
                group: 'Receiving',
                name: 'Navigating Receiving on the Field App',
                linkRoute: 'field-navigating-receiving-on-the-field-app'
              },
              {
                path: '',
                group: 'Receiving',
                name: 'Viewing a Purchase Order on the Field App',
                linkRoute: 'field-viewing-a-purchase-order-on-the-field-app'
              },
              {
                path: '',
                group: 'Receiving',
                name: 'Receiving Against a Purchase Order on the Field App',
                linkRoute: 'field-receiving-against-a-purchase-order-on-the-field-app'
              }
            ]
          },
          {
            path: '',
            group: 'Warehouse Mobile',
            linkRoute: 'toolWatch-warehouse-mobile'
          },
          {
            path: '',
            group: 'Mobile Introduction',
            groupName: 'Introduction',
            linkRoute: 'warehouse-introduction'
          },
          {
            path: '',
            group: 'Mobile Introduction',
            groupName: 'Introduction',
            children: [
              {
                path: '',
                group: 'Installation and Login',
                linkRoute: 'warehouse-installation-and-login'
              }
            ]
          },
          {
            path: '',
            group: 'Mobile Introduction',
            groupName: 'Introduction',
            children: [
              {
                path: '',
                group: 'Licensing Requirements',
                linkRoute: 'warehouse-licensing-requirements'
              }
            ]
          },
          {
            path: '',
            group: 'Warehouse Menu Screen',
            groupName: 'Menu Screen',
            linkRoute: 'warehouse-menu-screen'
          },
          {
            path: '',
            group: 'Warehouse Menu Screen',
            children: [
              {
                path: '',
                group: 'Settings',
                linkRoute: 'warehouse-settings'
              }
            ]
          },
          {
            path: '',
            group: 'Warehouse Menu Screen',
            children: [
              {
                path: '',
                group: 'Search',
                linkRoute: 'warehouse-search'
              },
              {
                path: '',
                group: 'Search',
                name: 'Performing a manual search',
                linkRoute: 'warehouse-performing-a-manual-search'
              }
            ]
          },
          {
            path: '',
            group: 'Warehouse Menu Screen',
            children: [
              {
                path: '',
                group: 'Transfer',
                linkRoute: 'warehouse-transfer'
              },
              {
                path: '',
                group: 'Transfer',
                name: 'Creating a Transfer Ticket',
                linkRoute: 'warehouse-creating-a-transfer-ticket'
              },
              {
                path: '',
                group: 'Transfer',
                name: 'Adding Items to a Transfer Ticket',
                linkRoute: 'warehouse-adding-items-to-a-transfer-ticket'
              },
              {
                path: '',
                group: 'Transfer',
                name: 'Submitting a Transfer Ticket',
                linkRoute: 'warehouse-submitting-a-transfer-ticket'
              }
            ]
          },
          {
            path: '',
            group: 'Warehouse Menu Screen',
            children: [
              {
                path: '',
                group: 'Pick Ticket',
                linkRoute: 'warehouse-pick-ticket'
              },
              {
                path: '',
                group: 'Pick Ticket',
                name: 'Sorting/Viewing a Pick Ticket',
                linkRoute: 'warehouse-sorting-viewing-a-pick-ticket'
              },
              {
                path: '',
                group: 'Pick Ticket',
                name: 'Picking a Pick Ticket',
                linkRoute: 'warehouse-picking-a-pick-ticket'
              },
              {
                path: '',
                group: 'Pick Ticket',
                name: 'Transferring Items and Completing a Pick Ticket',
                linkRoute: 'warehouse-transferring-items-and-completing-a-pick-ticket'
              }
            ]
          },
          {
            path: '',
            group: 'Warehouse Menu Screen',
            children: [
              {
                path: '',
                group: 'Express Check In/Out',
                linkRoute: 'warehouse-express-check-in-out'
              },
              {
                path: '',
                group: 'Express Check In/Out',
                name: 'Creating and Submitting Express Check In Ticket',
                linkRoute: 'warehouse-creating-submitting-express-check-in-ticket'
              },
              {
                path: '',
                group: 'Express Check In/Out',
                name: 'Creating and Submitting Express Check Out Ticket',
                linkRoute: 'warehouse-creating-submitting-express-check-out-ticket'
              }
            ]
          },
          {
            path: '',
            group: 'Warehouse Menu Screen',
            children: [
              {
                path: '',
                group: 'Count Inventory',
                linkRoute: 'warehouse-count-inventory'
              },
              {
                path: '',
                group: 'Count Inventory',
                name: 'Creating a Un-populated Count Ticket',
                linkRoute: 'warehouse-creating-a-un-populated-count-ticket'
              },
              {
                path: '',
                group: 'Count Inventory',
                name: 'Counting Items on a Un-Populated Count Ticket',
                linkRoute: 'warehouse-counting-items-on-a-un-populated-count-ticket'
              },
              {
                path: '',
                group: 'Count Inventory',
                name: 'Editing Counted Items',
                linkRoute: 'warehouse-editing-count-lines'
              },
              {
                path: '',
                group: 'Count Inventory',
                name: 'Counting a Pre-populated Ticket',
                linkRoute: 'warehouse-counting-a-pre-populated-ticket'
              }
            ]
          },
          {
            path: '',
            group: 'Warehouse Menu Screen',
            children: [
              {
                path: '',
                group: 'Create New Item',
                linkRoute: 'warehouse-create-new-item'
              },
              {
                path: '',
                group: 'Create New Item',
                name: 'Entering Model Information',
                linkRoute: 'warehouse-entering-model-information'
              },
              {
                path: '',
                group: 'Create New Item',
                name: 'Add Items to a Model Record',
                linkRoute: 'warehouse-add-items-to-a-model-record'
              }
              // {
              //   path: '',
              //   group: 'Create New Item',
              //   name: 'Create New Item from Existing Model',
              //   linkRoute: 'warehouse-create-new-item-from-existing-model'
              // },
              // {
              //   path: '',
              //   group: 'Create New Item',
              //   name: 'Editing Existing Model Record',
              //   linkRoute: 'warehouse-editing-existing-model-record'
              // },
              // {
              //   path: '',
              //   group: 'Create New Item',
              //   name: 'Editing Unique Tool Records',
              //   linkRoute: 'warehouse-editing-unique-tool-records'
              // }
            ]
          },
          {
            path: '',
            group: 'Warehouse Menu Screen',
            children: [
              {
                path: '',
                group: 'Receiving',
                linkRoute: 'warehouse-receiving'
              },
              {
                path: '',
                group: 'Receiving',
                name: 'Navigating Receiving in the Warehouse App',
                linkRoute: 'warehouse-navigating-receiving-in-the-warehouse-app'
              },
              {
                path: '',
                group: 'Receiving',
                name: 'Viewing a Purchase Order on the Warehouse App',
                linkRoute: 'warehouse-viewing-a-purchase-order-on-the-warehouse-app'
              },
              {
                path: '',
                group: 'Receiving',
                name: 'Receiving Against a Purchase Order in the Warehouse App',
                linkRoute: 'warehouse-receiving-against-a-purchase-order-in-the-warehouse-app'
              }
            ]
          }
        ]
      },
      {
        path: 'support',
        name: 'Support',
        routeName: 'Support',
        linkRoute: 'Support',
        group: 'Home',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            component: SupportPage,
            name: 'Support',
            meta: {
              breadcrumb: 'Support'
            }
          },
          {
            path: 'thanks',
            component: SupportThanksPage
          }
        ]
      },
      {
        path: 'https://www.toolwatch.com/product/updates',
        name: "What's New",
        isExternal: true,
        group: 'Home'
      }
    ]
  }
]

export default routes
